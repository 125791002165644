import React from 'react';

import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';

import _ImageGallery from '../../components/ImageGallery/ImageGallery';
import Layout from '../../components/layout';

const ImageGallery = styled(_ImageGallery)`
    margin-top: 30px;
    margin-bottom: 60px;
`;

const query = graphql`
    {
        allFile(
            filter: { relativeDirectory: { eq: "gallery/portrait" } }
            sort: { fields: [birthTime], order: DESC }
        ) {
            edges {
                node {
                    dir
                    modifiedTime
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

const GalleryPage = ({ location }) => {
    const data = useStaticQuery(query);
    const pictures = data.allFile.edges.map(
        edge => edge.node.childImageSharp.fluid
    );

    return (
        <Layout location={location}>
            <h1 className="major">Portraits</h1>
            <p>
                En studio ou à l'extérieur, le portrait est un domaine
                passionnant. Il ne s'agit pas d'un cadrage ou d'un éclairage,
                mais de mettre en lumière un aspect de la personnalité du
                modèle. Faire le portrait d'une personne, c'est raconter une
                histoire, réelle ou fictive, à travers une photographie. C'est
                faire ressortir l'intériorité de cette personne.
            </p>
            <ImageGallery pictures={pictures} />
        </Layout>
    );
};

export default GalleryPage;
